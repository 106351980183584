import React from 'react'
import Layout from './Layout'
import Clientbanner from './Clientbanner'
import ClienrtSection2 from './ClienrtSection2'

const Clients = () => {
  return (
    <Layout>
      {/* bannerSec */}
       <Clientbanner/>
       {/* section2 */}
       <ClienrtSection2/>
    </Layout>
  )
}

export default Clients