import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCheck } from '@fortawesome/free-solid-svg-icons';
import EmLogo from '../Assts/Emlogo.png';
import vs from '../Assts/VS.png';
import AOS from "aos";
import "aos/dist/aos.css";

const withoutCampusFeatures = [
  { title: 'Manual', description: 'Static PDFs, manual updates' },
  { title: 'Generic', description: 'Paper cards, manual inventory' },
  { title: 'Old', description: 'Email attachments, lost papers' },
  { title: 'Lost Information', description: 'Phone calls, handwritten notes' },
  { title: 'Delayed', description: 'Manual calculations, paperwork' },
  { title: 'Inefficient', description: 'Time-consuming paperwork, inefficient workflows' },
];

const withCampusFeatures = [
  { title: 'Automated', description: 'Drag-and-drop scheduling, visual overviews, mobile access' },
  { title: 'Smart', description: 'Barcode scanning, automated reminders, digital loans and returns' },
  { title: 'Fast', description: 'Secure online platform, automated feedback tools, syllabus version control' },
  { title: 'Instant', description: 'Instant alerts, two-way messaging, progress reports' },
  { title: 'Automated', description: 'Automated fee tracking, online payments, integrated reports' },
  { title: 'Streamlined', description: 'Automated workflows, digital approvals, data-driven insights' },
];

const Section7Home = () => {
  useEffect(() => {
    const isPhone = window.innerWidth <= 768;
    AOS.init({
      once: false,
      disable: isPhone,
      duration: 700,
      easing: "ease-out-cubic",
    });
    AOS.refresh();
  }, []);

  return (
    <section className='py-9 bg-gradient-to-l from-[#f2eeff4b] from-10% to-[#634fe63f] to-90% relative overflow-hidden'>
      <div className="container">
        <img src={vs} alt="" className='w-[12%] md:w-[3%] h-auto absolute top-[58.5%] md:top-[50%] right-[29%] md:right-[33.5%] z-10' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400" />
        <h2 className='font-[Helvetica] font-bold text-[24px] sm:text-[40px] mb-2 capitalize text-center' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">Supercharge Your School with MyLeading Campus<span className='text-[24px] sm:text-[40px]'>®</span></h2>
        <h3 className='text-[18px] sm:text-3xl font-medium text-center' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">Empower educators and students. Communication, operations, all in one</h3>
        <div className="grid grid-cols-3 md:grid-cols-3 mt-[30px] md:mt-[130px] gap-2 md:gap-7 relative">
          <div className='col-span-1 mt-[85px] md:mt-[3px]' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
            <ul>
              {['Timetable Management', 'Library Management', 'Upload Assignments & Syllabus', 'Easy Parent Communication', 'Fees Management', 'Streamlined Administrative Tasks'].map((feature, index) => (
                <li key={index} className='text-[15px] font-semibold text-[#141414] border border-x-0 border-y-0 border-t-[1px] w-full border-[#D9D9D9] h-[65px]'>
                  {feature}
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-1 flex flex-col" data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
            <div className="card-box border border-x border-y border-[#eaeaea50] p-7 bg-gradient-to-r to-[#f2eeff2c] from-[#F1FCFF] rounded-[20px] py-3 px-5 mt-[0] md:-mt-[76px] flex-1">
              <h4 className='text-[15px] font-semibold text-[#141414]'>Without MyLeading Campus<span className='text-[18px]'>®</span></h4>
              <h6 className='text-[14px] font-normal text-[#393939] pb-4 hidden md:block'>Manual workflows</h6>
              <ul>
                {withoutCampusFeatures.map((feature, index) => (
                  <li key={index} className='flex text-[18px] font-semibold text-[#141414] p-3 pb-6 border border-x-0 border-y-0 border-t-[1px] w-full border-[#D9D9D9] h-[65px]'>
                    <div className='flex-shrink-0 mr-2'>
                      <FontAwesomeIcon icon={faClose} className='w-[15px] h-[15px] rounded-full bg-[#D90000] p-1 text-white align-middle' />
                    </div>
                    <div className='flex-grow hidden md:block'>
                      <h4 className='text-[16px]'>{feature.title}</h4>
                      {/* <h6 className='text-[16px] font-normal text-[#393939]'>{feature.description}</h6> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-span-1 flex flex-col" data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
            <div className="card-box bg-[#fff] shadow-lg rounded-[20px] py-3 px-5 mt-[0px] md:mt-[-76px] flex-1">
              <div className='flex'>
                <div className='flex-shrink-0 mr-2 hidden md:block'>
                  <img src={EmLogo} alt="" className='w-[30px] md:w-[40px] h-[30px] md:h-[40px]' />
                </div>
                <div>
                  <h4 className='text-[15px] md:text-[18px] font-semibold text-[#141414] bg-gradient-to-r to-[#542DFF] from-[#21A9D5] text-transparent bg-clip-text'>With MyLeading Campus<span className='text-[15px] md:text-[18px] '>®</span></h4>
                  <h6 className='text-[14px] font-normal text-[#393939] pb-4 hidden md:block'>Efficient and thriving school community</h6>
                </div>
              </div>
              <ul>
                {withCampusFeatures.map((feature, index) => (
                  <li key={index} className='flex text-[18px] font-semibold text-[#141414] p-3 pb-6 border border-x-0 border-y-0 border-t-[1px] w-full border-[#D9D9D9] h-[65px]'>
                    <div className='flex-shrink-0 mr-2'>
                      <FontAwesomeIcon icon={faCheck} className='w-[15px] h-[15px] rounded-full bg-gradient-to-r to-[#542DFF] from-[#21A9D5] p-1 text-white align-middle' />
                    </div>
                    <div className='flex-grow hidden md:block'>
                      <h4 className='text-[16px] bg-gradient-to-r to-[#542DFF] from-[#21A9D5] text-transparent bg-clip-text font-bold'>{feature.title}</h4>
                      {/* <h6 className='text-[16px] font-normal text-[#393939]'>{feature.description}</h6> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section7Home;
