"use client";

import React, { useState } from 'react';
import BlogImg from '../Assts/blog1.png';
import BLogImg2 from '../Assts/blogPage2.png';
import BlogImg3 from '../Assts/blogPage3.png';
import BlogImg4 from '../Assts/blog4.png';
import BlogImg5 from '../Assts/blog5.png';
import BlogImg6 from '../Assts/blog6.png';
import BlogImg7 from '../Assts/blog7.png';
import BlogImg8 from '../Assts/blog8.png';
import { Pagination } from "flowbite-react";
import BlogSide from '../Assts/blogSide.jpg';
import BlogSide2 from '../Assts/blogSide2.jpg';
import BlogSide3 from '../Assts/blogSide3.jpg';
import BlogSide4 from '../Assts/blogSide4.jpg'
import BlogButton from './BlogButton';

const BlogSec2 = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const blogItems = [
    { img: BlogImg, time: '02 April 2021 By MyLeading Campus®', heading: 'The Pros and Cons of Homeschooling: Is It Right for Your Child?', desc: 'Homeschooling can provide a flexible and individualized education for children, but it also requires a significant amount of time, resources, and commitment from parents.' },
    { img: BLogImg2, time: '26 May 2021 By MyLeading Campus®', heading: 'How to Choose the Right School for Your Child: Factors to Consider', desc: "Choosing the right school for your child is an important decision that can impact their academic and personal growth." },
    { img: BlogImg3, time: '04 June 2021 By MyLeading Campus ®', heading: "How to Help Your Child Succeed in School: Tips for Parents", desc: "International education offers numerous benefits for students, including the opportunity to experience new cultures, learn a new language, gain a global perspective, and develop independence and resilience." },
    { img: BlogImg4, time: '15 July 2021 By MyLeading Campus ®', heading: "The Benefits of International Education: Why Your Child Should Study Abroad", desc: "International education offers numerous benefits for students, including the opportunity to experience new cultures, learn a new language, gain a global perspective, and develop independence and resilience." },
    { img: BlogImg5, time: '20 August 2021 By MyLeading Campus ®', heading: "The Future of Education: Trends and Innovations to Watch", desc: "The future of education is rapidly evolving, with technology and innovation driving major changes in the way we teach and learn." },
    { img: BlogImg6, time: '04 September 2021 By MyLeading Campus ®', heading: "The Benefits of Extracurricular Activities: Why They Matter for Your Child's Development", desc: "Extracurricular activities play a crucial role in the overall development of children. They provide opportunities for children to explore their interests, develop new skills, build relationships, and learn important life lessons outside of the classroom." },
    { img: BlogImg7, time: '02 October 2021 By MyLeading Campus ®', heading: "The Impact of Social Media on Education: Risks and Opportunities", desc: "Social media has become an integral part of our daily lives. With over 4 billion active social media users worldwide, it's no surprise that these platforms are also having an impact on education." },
    { img: BlogImg8, time: '12 November 2021 By MyLeading Campus ®', heading: 'How to make a School best and top ranking in the world.', desc: "As educators and administrators, we all strive to create a learning environment that is supportive, engaging, and effective." },
  ];
  // blogside
  const BlogSideItem = [
    {
      SideImg : BlogSide,
      Desc : "How to Promote Critical Thinking in the Classroom: Strategies and Activities.",
      time : "1 December 2024"
    },
    {
      SideImg : BlogSide2,
      Desc : "10 Simple Ways to Boost Your Immune System and Stay Healthy.",
      time : "04 January 2024"
    },
    {
      SideImg : BlogSide3,
      Desc : "The Power of Positive Thinking: How to Overcome Self-Doubt and Build Confidence.",
      time : "11 December 2024"
    },
    {
      SideImg : BlogSide4,
      Desc : "5 Technologies That Are Shaping the Future of Education.",
      time : "08 March 2024"
    },
  ]

  const totalPages = Math.ceil(blogItems.length / itemsPerPage);

  const currentItems = blogItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <section className='mt-[40px]'>
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              {currentItems.map((item, index) => (
                <div className="col-span-1" key={index}>
                  <div>
                    <img src={item.img} alt={item.heading} />
                    <h5 className='text-[16px] text-[#696969] capitalize mb-1 mt-4'>{item.time}</h5>
                    <h3 className='text-[20px] text-[#3b566e] hover:text-[#1f8ceb] leading-[24px] font-semibold mb-3'>{item.heading}</h3>
                    <p className='text-[18px] leading-[26px] text-[#6f8ba4]'>{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
            {/* Pagination */}
            <div className="flex overflow-x-auto sm:justify-start mt-5 mb-[20px]">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
          <div className="col-span-4">

            <form class="max-w-lg mx-auto">
              <div class="flex">
                <div class="relative w-full">
                  <input type="search" id="search" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Search..." required />
                  <button type="submit" class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                    <span class="sr-only">Search</span>
                  </button>
                </div>
              </div>
            </form>
            <h3 className='text-[22px] font-semibold mb-[20px] mt-[20px]'>Categories</h3>
            <ul>
               <li className=' border-b-[1px] leading-[50px]'>New Education Policy (06)</li>
               <li className=' border-b-[1px] leading-[50px]'>Need for School Management ERP (12)</li>
               <li className=' border-b-[1px] leading-[50px]'>CBSE/ICSE Exam Tips (08)</li>
               <li className=' border-b-[1px] leading-[50px]'>Use of Technology in School (15)</li>
               <li className=' border-b-[1px] leading-[50px]'>Edtech Companies (10)</li>
            </ul>
            <div>
               <h3 className='text-[22px] font-semibold mb-[20px] mt-[20px]'>Recent Posts</h3>
               {/* first */}
               {BlogSideItem.map((item, index) => (
                  <div className='flex' key={index}>
                  <div className='flex-shrink-1 mr-4'>
                      <img src={item.SideImg} alt="" className='w-full'/>
                  </div>
                  <div className='flex-grow-0'>
                      <h4>{item.Desc}</h4>
                      <h6>{item.time}</h6>
                  </div>
                  </div>
               ))}
              
               {/* tags */}
               <div className='mb-[40px] mt-[20px]'>
                  <h3 className='text-[22px] font-semibold mb-[20px]'>Tags</h3>
                  <BlogButton label="New Education Policy" />
                  <BlogButton label="School Management ERP"/>
                  <BlogButton label=" School Management Mobile App"/>
                  <BlogButton label="Free Campus Management Software"/>
                   <BlogButton label=" Biometric Attendance"/>
                   <BlogButton label="ICSE CBSE Exam Patterns"/>
                   <BlogButton label="BSE ICSE Exam Tips"/>
                   <BlogButton label="School Colleges & Universities"/>
               </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSec2;
