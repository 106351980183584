import React from 'react'
import SectionWithBackground from './SectionComponent'

const BlogBanner = () => {
  return (
     <SectionWithBackground
       title="Blog"
       breadcrumbs={[
           {label: "Home", link: "/home"},
           {label: "Blog", link: ""}
       ]}
       backgroundImage="https://placehold.co/600x400"
     />
  )
}

export default BlogBanner