import React from 'react'
import { cn } from '../Utils/cs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//test comment
const ButtonReuse = ({
  type,
  title,
  className,
  onClick,
  disabled=false,
  extra
}) => {
  return (
    <div className='block md:inline-block text-center md:text-start w-full md:w-auto'>
      <button disabled={disabled} type={type || "button"} onClick={onClick ? onClick : () => { }} className={cn("text-white  focus:ring-0 focus:outline-none text-sm sm:px-2 sm:py-2 text-center dark:focus:ring-blue-800 focus: outline-0 focus-visible:outline-none py-[8px] px-[35px] rounded-[25px] text-[16px] font-semibold outline-none bg-gradient-to-r from-[#622BFF] to-[#16B2D0] uppercase", className)}>
        {extra}
        {title}
      </button>
    </div>
  )
}

export default ButtonReuse