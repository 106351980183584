import React from 'react'
import SectionWithBackground from './SectionComponent'

const FeaturesBanner = () => {
  return (
     <SectionWithBackground
        title="Features"
        breadcrumbs={[
            {label: "Home", link: '/home'},
            {label: "Features", link: ''}
        ]}
        backgroundImage="https://placehold.co/600x400"
     />
  )
}

export default FeaturesBanner