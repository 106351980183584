import React from 'react'
import Layout from './Layout'
import BlogBanner from './BlogBanner'
import BlogSec2 from './BlogSec2'

const Blog = () => {
  return (
    <Layout>
         {/* blog banner */}
         <BlogBanner/>
         {/* BLogSec2 */}
         <BlogSec2/>
    </Layout>
  )
}

export default Blog