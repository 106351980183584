import React from 'react'
import Layout from './Layout'
import FeaturesBanner from './FeaturesBanner'
import FeaturesSec2 from './FeaturesSec2'
import FeaturesSec3 from './FeaturesSec3'

const Features = () => {
  return (
     <Layout>
        {/* features banner */}
        <FeaturesBanner/>
        {/* section2 */}
        <FeaturesSec2/>
        {/* section3 */}
        <FeaturesSec3/>
     </Layout>
  )
}

export default Features