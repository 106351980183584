import React from 'react'
import Layout from './Layout'
import Contactbanner from './Contactbanner'
import ContactSec2 from './ContactSec2'
import ContectSec3 from './ContactSec3'

const ContactUs = () => {
  return (
    <Layout>
        {/* Contact banner */}
        <Contactbanner/>
        {/* sec2 */}   
        <ContactSec2/>
        {/* contactForm */}
       <ContectSec3/>
    </Layout>
  )
}

export default ContactUs