import React from 'react'
import { Modal } from 'flowbite-react';
import ContactForm from './ContactForm';


const ContactModal = ({
    openModal,
    setOpenModal,
}) => {

    return (
        <Modal show={openModal} onClose={() => setOpenModal(false)} >
                <Modal.Header><h2 className="font-[Helvetica] font-bold text-[20px] sm:text-[36px] leading-[21px] md:leading-[46px]  text-left w-[100%] bg-gradient-to-r from-[#3b566e] to-[#00ACCE] bg-clip-text text-transparent">Send  A Message</h2></Modal.Header>
                <Modal.Body>
                    <ContactForm />
                </Modal.Body>
        </Modal>
    )
}

export default ContactModal