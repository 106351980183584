import React from 'react'
import SectionWithBackground from './SectionComponent'

const Clientbanner = () => {
  return (
    <SectionWithBackground
     title="Client"
     breadcrumbs={[
        {label: "Home", link: '/home'},
        {label: 'Client',link: ""}
     ]}
     backgroundImage="https://placehold.co/600x400"
    />
  )
}

export default Clientbanner