import React,{useEffect} from 'react';
import shortBanner from '../Assts/shortbannerOne.png';
import shortBanner2 from '../Assts/shortbannerTwo.png';
import ButtonReuse from '../Components/ButtonReuse';
import AOS from "aos";
import "aos/dist/aos.css";
import { AppContext } from '../App';

const Section8Home = () => {
  const {setOpen} = React.useContext(AppContext);
   // useffect
   useEffect(() => {
    const isPhone = window.innerWidth <= 768;
    AOS.init({
      once: false,
      disable: isPhone,
      duration: 700,
      easing: "ease-out-cubic",
    });
    AOS.refresh();
  }, []);

  return (
    <section className='bg-gradient-to-r from-[#7660FB] from-10% to-[#28A4D9] to-90% py-12'>
      <div className="container mx-auto">
        <div className="grid grid-cols-[100%] sm:grid-cols-[70%,30%] gap-0 md:gap-0 items-center text-center md:text-start md:items-starts">
          {/* Text Section */}
          <div className='col-span-1' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
            <h2 className='text-[30px] md:text-[37px] font-semibold text-white leading-[2rem] md:leading-[2rem] mb-2 md:mb-[20px]'>
              One Software to replace them all....
            </h2>
            <p className='mt-3 text-[26px] font-medium bg-gradient-to-r from-[#FBFAFF] to-[#FFEDF1] via-[#F2FDFF] bg-clip-text text-transparent w-full md:w-[83%] leading-9'>
              Streamlines every aspect of your school's operations, from academics to administration.
            </p>
            <ButtonReuse onClick={() => {
                  setOpen(true);
                }} title="Get Demo" className="animate-updown px-[25px] md:px-[35px] py-[10px] md:py-[10px] bg-gradient-to-r from-[#fff] to-[#fff] text-[12px] md:text-[16px] text-[#000] mt-[35px] block w-full md:w-autos"/>
          </div>

          {/* Image Section */}
          <div className="col-span-1 hidden md:block" data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
            <div className="relative sm:visible -m-r-[-93%] -right-[-70px]">
              <img src={shortBanner} alt="" className='w-[75%] h-auto' />
              <img src={shortBanner2} alt="" className='w-[86%] h-auto absolute top-[-9px] right-[-66px]' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section8Home;
