import React from 'react'
import SectionWithBackground from './SectionComponent'

const TestimonialSec1 = () => {
  return (
    <SectionWithBackground
       title="Testimonial"
       breadcrumbs={[
        {label: "Home", link:"/home"},
        {label: "Testimonial", link:''}
       ]}
       backgroundImage="https://placehold.co/600x400"
    />
  )
}

export default TestimonialSec1