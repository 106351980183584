import React from 'react'
import Layout from './Layout'
import PrivacySec1 from './PrivacySec1'

const PrivacyPolicy = () => {
  return (
      <Layout>
           {/* pp bannner */}
           <PrivacySec1/>    
      </Layout>
  )
}

export default PrivacyPolicy