import React, { useState, useEffect } from 'react';
import bannerimg1 from '../Assts/herobanner.jpeg';
import bannerimg2 from '../Assts/Blog.png'; 
import bannerimg3 from '../Assts/Blog2.png';
import bannerimg4 from '../Assts/Blog3.png';
import bannerCalender from '../Assts/herocalender.png';
import ButtonReuse from '../Components/ButtonReuse';
import presentation from '../Assts/heroicon2.png';
import pieChar from '../Assts/heroicon3.png';
import analytics from '../Assts/heroicon4.png';
import bannerCircleM from '../Assts/bannerCirclemiddlwe.png';
import bookHomeWork from '../Assts/bannerhomework.png';
import SchollBus from '../Assts/bannercricleBus.png';
import education from '../Assts/bannerCircleonline.png';
import chat from '../Assts/banneCirleChat.png';
import Typewriter from 'typewriter-effect';
import AppStoreBtn from '../Components/AppStoreBtn';
import PlayStoreBtn from '../Components/PlayStoreBtn';
import { AppContext } from '../App';

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const {setOpen} = React.useContext(AppContext);

  const typewriterStrings = [
    { text: "School Management", image: bannerimg1 },
    { text: "Student Information Systems", image: bannerimg2 },
    { text: "Parent-Teacher Communication", image: bannerimg3 },
    { text: "Classroom Scheduling", image: bannerimg4 },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % typewriterStrings.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [typewriterStrings.length]);

  return (
    <section className="banner mt-[2.75rem] md:mt-3 sm:mt-64 md:relative">
      <div
        className="bg-cover bg-center w-full h-auto flex items-start justify-start"
      >
        <div className="w-full h-full flex items-center banner-bg">
          <div className="max-w-full h-auto md:h-auto sm:max-w-7xl mx-auto p-8 text-start">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-8 items-start">
              
              {/* Column 1 */}
              <div className="flex flex-col justify-start items-start md:items-start text-white space-y-6 banner-info order-2 sm:order-1 md:order-1">
                <h6 className='text-[14px] sm:text-[20px] mt-[40px] sm:mt-[100px]'>
                  All-In-One Platform
                </h6>
                <h2 className="text-[18px]  sm:text-[34px] md:text-[37px] lg:text-[37px] font-bold">
                  Unified Platform for Streamlined{' '}
                  <Typewriter
                    options={{
                      autoStart: true,
                      loop: true,
                      delay: 100,
                      strings: typewriterStrings.map(item => item.text),
                    }}
                    onInit={(typewriter) => {
                      typewriterStrings.forEach((item, index) => {
                        typewriter.typeString(item.text)
                          .pauseFor(3000)
                          .deleteAll()
                          .callFunction(() => setCurrentIndex(index));
                      });
                      typewriter.start();
                    }}
                  />
                </h2>
                <p className="text-sm sm:text-xl md:text-xl text-start md:text-start">
                  All-in-one school management software for streamlined operations and enhanced communication.
                </p>
                <div className="flex flex-col sm:w-full sm:flex-row lg:w-full md:flex-row items-center gap-4 w-full">
                  <div className=' flex gap-2'>
                    <AppStoreBtn />
                    <PlayStoreBtn/>
                  </div>
                  <div className='w-full block sm:w-full md:w-full lg:w-full'>
                    <ButtonReuse onClick={() => {setOpen(true)}} title="Get Started"className="px-[15px] hidden sm:block md:px-[35px] py-[10px] md:py-[10px] text-[12px] md:text-[16px]" />
                    {/* mobile view */}
                    <ButtonReuse onClick={() => {setOpen(true)}} title="Get Started For Free"className="px-auto block sm:hidden md:px-[35px] py-[10px] md:py-[10px] text-[12px] md:text-[16px] w-full sm:w-auto" />
                  </div>
                </div>
              </div>
              
              {/* Column 2 */}
              <div className=" grid-cols-2 hidden sm:grid-cols-2 sm:grid gap-8 mt-20 md:mt-20 order-2 sm:order-1 md:order-1">
                <div className="flex flex-col items-start flex-auto">
                  <img src={typewriterStrings[currentIndex].image} alt="Banner" className="rounded-2xl w-64 h-72 mb-4 shadow-2xl object-cover" />
                  <div className="flex items-center w-11/12 bg-white rounded-2xl shadow-sm p-2 md:p-4">
                    <img src={bannerCalender} alt="Calendar" className="w-12 h-auto" />
                    <p className="ml-4 text-gray-700 text-start font-medium">
                      <span className="font-bold">Track Attendance</span> <br /> Get real-time Insights
                    </p>
                  </div>
                </div>

                <div className="flex flex-col space-y-8 flex-auto">
                  <div className="grid grid-cols-2 gap-4 animate-updown">
                    <div className="flex justify-center items-center bg-white shadow-sm rounded-full w-20 h-20">
                      <img src={presentation} alt="Presentation" className="w-12 h-auto" />
                    </div>
                    <div className="flex justify-center items-center bg-white shadow-sm rounded-full w-20 h-20 animate-updown">
                      <img src={pieChar} alt="Pie Chart" className="w-12 h-auto translateYAnimation" />
                    </div>
                  </div>
                  <div className="flex items-center bg-white rounded-2xl shadow-sm p-4 w-11/12">
                    <img src={analytics} alt="Analytics" className="w-10 h-auto" />
                    <p className="ml-4 text-gray-700 text-start font-semibold">Grow Your School <br /> Blossom Results</p>
                  </div>
                  {/* Circle Box */}
                  <div className="flex justify-center items-center shadow-sm p-4 w-full">
                    <div className="relative mt-10 outline outline-offset-8 outline-1 rounded-full" style={{ outlineColor: "#0366D6", outlineOffset: '45px' }}>
                      {/* Main Image */}
                      <img src={bannerCircleM} alt="Main Circle" className="w-32 h-32 md:w-16 md:h-16 rounded-full object-cover shadow-lg this-main z-10 transform -scale-x-100" />
                    
                      {/* Surrounding Images */}
                      <div className="absolute inset-0 flex justify-center items-center animate-spin-slow">
                        {/* Top Left Image */}
                        <img src={bookHomeWork} alt="Book Homework" className="w-12 h-12 rounded-full shadow-md absolute -top-10 -left-10 p-2 bg-white translate-x-[225%] translate-y-[7%] animate-spin2-slow" />

                        {/* Top Right Image */}
                        <img src={SchollBus} alt="School Bus" className="w-12 h-12 rounded-full shadow-md absolute top-16 left-16 p-2 bg-white translate-x-[-33%] translate-y-[-8%] animate-spin2-slow" />

                        {/* Bottom Left Image */}
                        <img src={education} alt="Education" className="w-12 h-12 rounded-full shadow-md absolute top-16 -left-12 p-2 bg-white translate-x-[17%] translate-y-[-2%] animate-spin2-slow" />

                        {/* Bottom Right Image */}
                        <img src={chat} alt="Chat" className="w-12 h-12 rounded-full shadow-md absolute -top-9 left-16 p-2 bg-white translate-x-[-125%] translate-y-[-43%] animate-spin2-slow" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Column 2 end */}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile button */}
      <div className='fixed bottom-0 left-0 right-0 w-full shadow-lg z-50 sm:hidden md:hidden'>
        <ButtonReuse onClick={() => {setOpen(true)}} title="Get Started For Free" className="w-full p-3 text-sm md:text-base rounded-none" />
      </div>
    </section>
  );
};

export default Banner;
