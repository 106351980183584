import React from 'react';
import Navbarheader from './Navbarheader';
import Footer from './Footer';

const Layout = ({children}) => {
  return (
      <>
       <Navbarheader/>
       <div>{children}</div>
       <Footer/>
      </>
  )
}

export default Layout