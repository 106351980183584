import React from 'react';
import Layout from './Layout';
// import SectionBannerHome from './SectionBannerHome';
import { InfiniteMovingCardsDemo } from '../Components/ui/infiniteMovingCardsComponent';
import AboutSection from './AboutSection';
import Banner from './SectionBannerHome';
import AboutSection2 from './AboutSection2';
import AboutSection3 from './AboutSection3';
import Section6Home from './Section6Home';
import Section7Home from './Section7Home';
import Section8Home from './Section8Home';
import Section10Home from './Section10Home';
import Section11Home from './Section11Home';
import Section12Home from './Section12Home';
import Seciton9Home from './Section9Home';


// import Section2Home from './Section2Home';

const Home = () => {
  return (
    <Layout>
      {/* banner */}
      <Banner/>  
      {/* brand sec */}
      {/* <Section2Home/> */}
      <InfiniteMovingCardsDemo/>  
      {/* about Sec */}
      <AboutSection/>
      {/* AboutSection2 */}
      <AboutSection2/>
      {/* Growing Scholl */}
      <AboutSection3/>
      {/* Scholl Managment */}
      <Section6Home/>
      {/* Empower */}
      <Section7Home/>
      {/* ShortBanner */}
      <Section8Home/>
      {/* testimonial  */}
       <Seciton9Home/>
      {/* accordion */}
      <Section10Home/>
      {/* blog */}
      <Section11Home/>
      {/* getInTouch */}
      <Section12Home/>
    </Layout>
  );
};

export default Home;
