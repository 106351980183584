import React from 'react'
import Layout from './Layout';
import TestimonialSec1 from './TestimonialSec1'
import TestimonialSec2 from './TestimonialSec2';

const Testimonials = () => {
  return (
      <Layout>
          {/* testimonial banner */}
           <TestimonialSec1/>
           {/* section 2 */}
           <TestimonialSec2/>
      </Layout>
  )
}

export default Testimonials