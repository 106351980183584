import React from 'react';
import FeaturesIcon from '../Assts/icon-23.png';
import FeaturesIcon2 from '../Assts/icon-24.png';
import FeaturesIcon3 from '../Assts/icon-25.png';
import FeaturesIcon4 from '../Assts/icon-26.png';
import FeaturesIcon5 from '../Assts/icon-27.png';
import FeaturesIcon6 from '../Assts/icon-28.png';

const FeaturesSec2 = () => {
    const FeaturesItem = [
        {
            img: FeaturesIcon,
            title: "Attendance Management",
            desc: "The feature helps Institute keep track of attendance by automating the process of attendance, making it easier for teachers and administrators to monitor data. It sends alerts to parents about their child's absence or late arrival. Biometric fingerprint, facial recognition, or RFID card methods ensure accurate and efficient attendance management.",
        },
        {
            img: FeaturesIcon2,
            title: "Gradebook Management",
            desc: "The software helps teachers manage and record grades for their students. Teachers can input assignments, quizzes, and test scores, and the software can automatically calculate the overall grade. It also provides students and parents access to grades, keeping them informed about academic performance.",
        },
        {
            img: FeaturesIcon3,
            title: "Communication Management",
            desc: "The software improves communication between teachers, administrators, parents, and students. It sends notifications and reminders about upcoming events, meetings, and assignments. It also facilitates communication between teachers and parents about a student's academic performance or behavior.",
        },
        {
            img: FeaturesIcon4,
            title: "Automated Reminders",
            desc: "This feature helps schools and teachers send timely reminders to students and parents about assignments, events, and fees. It reduces the chances of missing deadlines and keeps parents informed about progress. The feature automates reminders, saving time for the institute.",
        },
        {
            img: FeaturesIcon5,
            title: "Timetable Management",
            desc: "The software helps schools manage schedules and create timetables for classes, exams, and events. It ensures no scheduling conflicts and helps students and teachers keep track of their daily schedules. It also optimizes resources by allocating classrooms and teachers efficiently.",
        },
        {
            img: FeaturesIcon6,
            title: "Insightful Reports",
            desc: "This feature generates detailed reports on attendance, report cards, student performance, etc. It provides insights into areas of improvement and helps schools make data-driven decisions. The reports can be shared with administrators, teachers, and parents for better collaboration.",
        },
    ];

    return (
        <section className='my-14'>
            <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                    {FeaturesItem.map((item, index) => (
                        <div className="col-span-1" key={index}>
                            <div className='h-full border border-[#deeefc] p-5 rounded-md'>
                                <img src={item.img} alt="" className='w-12 h-12 animate-transformY' />
                                <h3 className='text-[20px] md:text-[20px] font-medium text-[#3b566e] mt-5 mb-3'>{item.title}</h3>
                                <p className='text-[17px] leading-[26px] text-justify text-[#585858] flex-grow'>{item.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default FeaturesSec2;
