import React from 'react';

const BlogButton = ({ label, link, className }) => {
  return (
    <a href={link}>
      <button
        className={`relative flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium  group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800 ${className}`}
      >
        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 group-hover:bg-opacity-0">
          {label}
        </span>
      </button>
    </a>
  );
};

export default BlogButton;
