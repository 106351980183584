import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faEnvelope, faPhone } from '@fortawesome/fontawesome-free-solid';

const ContactSec2 = () => {
  return (
    <section className='my-[40px]'>
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          {[
            {   
              icon: faPaperPlane,
              title: 'Location',
              content: 'RDB Boulevard, Block EP & GP Plot K-1, 5th Floor, Sector V, Kolkata, West Bengal 700055',
              link: '#', // Add a valid URL if necessary
            },
            {
              icon: faEnvelope,
              title: 'Email',
              content: 'hello@myleadingcampus.com',
              link: 'mailto:hello@myleadingcampus.com',
            },
            {
              icon: faPhone,
              title: 'Phone',
              content: '+91-9874344994',
              link: 'tel:+91-9874344994',
            },
          ].map((item, index) => (
            <div key={index} className="col-span-1 flex flex-col h-full">
              <div className="flex bg-white shadow-md rounded-lg p-6 flex-grow relative">
                <div className='flex-shrink-0 bg-gradient-to-r from-[#633EFF] to-[#00ACCE] w-[45px] h-[45px] rounded-full flex items-center justify-center mr-[10px] z-[1]'>
                  <FontAwesomeIcon icon={item.icon} className='text-white animate-transformY text-[24px]' />
                </div>
                <div className='flex-grow'>
                  <h3 className='text-[24px] font-semibold text-[#383838] mb-[10px]'>{item.title}</h3>
                  <p className='text-[16px] leading-[24px]'>
                    <a href={item.link}>{item.content}</a>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactSec2;
