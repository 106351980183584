import React from 'react';
import Layout from './Layout';
import AboutSec1 from './AboutSec1';
import Aboutsec2 from './AboutSec2';
import Aboutsec4 from './AboutSec4';
import AboutSec3 from './AboutSec3';

const AboutUs = () => {
  return (
    <Layout>
     {/* aboutbanner */}
      <AboutSec1/>
      {/* aboutsec2 */}
      {/* section4 */}
      <Aboutsec2/>
      {/* counter */}
      <AboutSec3/>
      <Aboutsec4/>
    </Layout>
  );
};

export default AboutUs;
