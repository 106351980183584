import React from 'react'
import { Link } from 'react-router-dom'

const SectionWithBackground = ({ title, breadcrumbs, backgroundImage }) => {
  return (
    <section 
        className="text-center bg-cover pt-[80px] bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="bg-black bg-opacity-50 py-[80px] pt-[60px]">
          <h2 className="text-white text-4xl font-bold">{title}</h2>
          <ul className="flex justify-center space-x-2 text-white mt-4">
            {breadcrumbs.map((breadcrumb, index) => (
              <ul key={index} className='flex'>
                {breadcrumb.link ? (
                  <li className='pr-3'>
                    <Link to={breadcrumb.link} className="hover:underline">
                      {breadcrumb.label}
                    </Link>
                  </li>
                ) : (
                  <li>{breadcrumb.label}</li>
                )}
                {index < breadcrumbs.length - 1 && <li>/</li>}
              </ul>
            ))}
          </ul>
        </div>
      </section>
  )
}

export default SectionWithBackground;
