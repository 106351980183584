import React, { useState,useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

const faqData = [
    {
        id: 1,
        question: 'What are the benefits of using MyLeading Campus?',
        answer: 'Experience streamlined workflow, enhanced communication, data-driven decision making, improved student engagement, and empowered parent involvement. Say goodbye to paperwork fatigue and hello to increased efficiency and a thriving school community.',
    },
    {
        id: 2,
        question: 'Is MyLeading Campus user-friendly and easy to learn?',
        answer: 'MyLeading Campus is designed with simplicity in mind, ensuring that users can easily navigate and utilize its features. The intuitive interface allows even those with limited technical knowledge to quickly learn and manage their tasks effectively.',
    },
    {
        id: 3,
        question: 'How much does MyLeading Campus cost?',
        answer: 'The cost of MyLeading Campus varies depending on the features and scale required by your institution. We offer flexible pricing plans to accommodate different needs, ensuring affordability without compromising on functionality.',
    },
    {
        id: 4,
        question: 'Is my data safe and secure?',
        answer: 'Your data’s security is our top priority. MyLeading Campus employs state-of-the-art encryption and security measures to protect your information. We ensure compliance with the latest data protection regulations to safeguard your school’s data.',
    },
];
const Section10Home = () => {
    // State to manage which accordion item is active
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
   // useffect
   useEffect(() => {
    const isPhone = window.innerWidth <= 768;
    AOS.init({
      once: false,
      disable: isPhone,
      duration: 700,
      easing: "ease-out-cubic",
    });
    AOS.refresh();
  }, []);
    return (
        <section className='bg-[#F2F5FF] py-10'>
            <div className="container">
                <h2 className='font-[Helvetica] font-bold text-[24px] sm:text-[40px] mb-2 capitalize text-center' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
                    Got Questions? We've Got Answers!
                </h2>
                <h3 className='text-[18px] sm:text-3xl font-medium  text-center w-[80%] block m-auto' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
                    Dive into our frequently asked questions to learn more about MyLeading Campus<span className='text-[18px] sm:text-3xl'>®</span>
                </h3>
                <div className="grid grid-cols-1 mt-9">
                    <div id="accordion-collapse" className='bg-white'>
                        {faqData.map((faq, index) => (
                            <div key={faq.id}>
                                <h2 id={`accordion-collapse-heading-${faq.id}`} className='transition ease-in-out delay-150'>
                                    <button
                                        type="button"
                                        className={`flex items-center justify-between w-full p-3 font-medium rtl:text-right border border-gray-200 gap-3 focus:ring-1 focus:ring-pur dark:focus:ring-purple-600 dark:border-purple-600 hover:bg-gray-100 dark:hover:bg-gray-800 transition ease delay-300 ${
                                            activeIndex === index
                                                ? 'bg-gradient-to-l to-[#ECE8FF] from-[#DDF7FF] text-gray-900 dark:bg-gray-700 dark:text-white'
                                                : 'text-gray-500 dark:text-gray-400'
                                        }`}
                                        aria-expanded={activeIndex === index}
                                        aria-controls={`accordion-collapse-body-${faq.id}`}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <span className='text-[14px] md:text-[20px] text-start md:flex  font-semibold text-[#000000] transition ease delay-300'>
                                            <span className='mr-3 text-[15px] hidden md:block md:text-2xl font-bold text-[#898888] transition ease-in-out delay-150' style={{ verticalAlign: 'top' }}>
                                                {`0${faq.id}`}
                                            </span>
                                            {faq.question}
                                        </span>
                                        {activeIndex === index ? (
                                          <svg className="w-[32px] h-[32px] bg-black rounded-full p-[6px] text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                      </svg>
                                      
                                        ) : (
                                            <svg className="w-[32px] h-[32px] bg-[#F3F5F6] rounded-full p-[6px] text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                                            </svg>
                                        )}
                                    </button>
                                </h2>
                                <div
                                    id={`accordion-collapse-body-${faq.id}`}
                                    className={`overflow-hidden transition ease-in-out delay-150 ${
                                        activeIndex === index ? 'max-h-screen' : 'max-h-0 hidden'
                                    }`}
                                    aria-labelledby={`accordion-collapse-heading-${faq.id}`}
                                >
                                    <div className="p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 transition ease-in-out delay-150">
                                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                                            {faq.answer}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section10Home;
