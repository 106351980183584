import React from 'react'
import SectionWithBackground from './SectionComponent';

const AboutSec1 = () => {
  return (
    <SectionWithBackground 
      title="About Us" 
      breadcrumbs={[
        { label: 'Home', link: '/home' },
        { label: 'About', link: '' }
      ]} 
      backgroundImage="https://placehold.co/600x400"
    />
  )
}

export default AboutSec1;
