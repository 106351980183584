import React, { useState, useEffect } from 'react';
import { FaPhoneAlt } from 'react-icons/fa'; // Import the call icon
import { NavLink, useNavigate } from 'react-router-dom'; // Import NavLink for active link styling
import logo from '../Assts/logo.png';
import '../App.css';
import ButtonReuse from '../Components/ButtonReuse';
import { AppContext } from '../App';

const Navbarheader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(null); // Track which submenu is open
  const [navbarBg, setNavbarBg] = useState(false);
  const { setOpen } = React.useContext(AppContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
  })
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSubmenuOpen(null); // Reset submenu when main menu toggles
  };

  const toggleSubmenu = (menuItem) => {
    setSubmenuOpen(submenuOpen === menuItem ? null : menuItem); // Toggle the specific submenu
  };

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbarBg(true);
    } else {
      setNavbarBg(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  const headerSpan = {
    fontWeight: "700",
    fontFamily: "Helvetica",
    color: "#743DFF",
    display: "block",
    marginBottom: "-10px",
    marginLeft: "-5px",
    marginTop: "5px"
  };

  return (
    <nav className={`px-4 py-2 lg:px-11 sm:px-0 fixed w-full z-20 top-0 left-0 border-b bg-white md:bg-transparent border-gray-200 dark:border-gray-600 ${navbarBg ? 'bg-white shadow-lg dark:bg-gray-900' : 'bg-transparent'} md:bg-white md:dark:bg-gray-900`} style={{ zIndex: "999" }}>
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between lg:justify-between sm:justify-start md:mr-2 md:ml-2 mx-auto md:mx-0 p-2">
        {/* Logo */}
        <NavLink onClick={scrollToTop} to="/home" className="flex items-center space-x-3 sm:mr-5 rtl:bg-repeat-space md:rtl:space-x-reverse">
          <img src={logo} className="h-11 w-11" alt="Logo" style={{ width: "34px", height: "auto", borderRadius: "5px" }} />
          <div className="flex flex-col mt-[-12px]">
            <NavLink to='/home'>
            <span className="text-[13px] sm:text-[16px] font-semibold whitespace-nowrap dark:text-white" style={headerSpan}>MYLEADING</span>
            <span className="text-[13px] sm:text-[16px] font-semibold whitespace-nowrap dark:text-white tracking-[1.5px]" style={headerSpan}>CAMPUS <span className='text-[13px] sm:text-[16px] ml-[-3px]'>®</span></span>
            </NavLink>
          </div>
        </NavLink>

        {/* Buttons */}
        <div className="flex md:order-2 space-x-3 rtl:space-x-reverse">
          {/* Call Now button on Mobile */}
          <div className='sm:mr-1 md:hidden py-[6px] px-[12px] text-[12px] sm:py-[3px] sm:px-[8px]'>
            <a href='tel:9874344994' className="flex items-center space-x-2 border-2 text-[1rem] border-gradient bg-transparent text-purple-600 py-1 px-3 rounded-lg">
              <FaPhoneAlt className="w-4 h-4" /> {/* Call Icon */}
              <span>Call Now</span>
            </a>
          </div>

          {/* Get Started For Free Button only on Desktop */}
          <div className='hidden md:block py-[6px] px-[12px] text-[12px] sm:py-[3px] sm:px-[8px]'>
            <ButtonReuse onClick={() => {setOpen(true)}} title="Get started For Free" className="text-[8px] md:text-[16px] px-[8px] md:px-[36px] py-[8px] md:py-[8px]" />
          </div>

          {/* Hamburger Menu */}
          <button
            type="button"
            className="inline-flex items-center p-2 w-12 h-12 justify-center text-2xl text-gray-500 md:hidden focus:outline-none dark:text-gray-400 dark:focus:ring-gray-600"
            aria-expanded={menuOpen}
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            {menuOpen ? (
              <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            )}
          </button>
        </div>

        {/* Fullscreen Menu */}
        <div className={`items-center justify-between ${menuOpen ? 'block' : 'hidden'} w-full md:flex md:w-auto h-[100vh] md:h-auto md:order-1 bg-white dark:bg-gray-900`}>
          <div className="w-full">
            {/* Login button at the top in mobile */}
            <div className="flex justify-end p-4 md:hidden">
              <button onClick={() => {
                const link = "https://school.myleadingcampus.com"
                window.open(link, "_blank")
              }} className="w-full bg-gradient-to-r from-[#622BFF] to-[#16B2D0] text-white py-2 rounded-lg text-2xl">Login</button>
            </div>
            <ul className="flex flex-col p-4 sm:p-0 md:flex-row md:space-x-8 md:mt-0 font-medium menu">
              {/* Main Menu Links */}
              <li>
                <NavLink
                onClick={scrollToTop}
                  to="/home"
                  className={({  isActive, isPending }) =>
                    
                    `block text-4xl leading-[5rem] md:leading-none md:text-[18px] py-2 sm:py-1 px-3 sm:px-1 ${isPending ? "pending" : isActive ? "active" : ""} md:hover:text-blue-700`
                  }
                >
                  Home
                </NavLink>
              </li>

              <li className="relative">
                {/* <div className="flex justify-between items-center">
                  <NavLink
                  onClick={scrollToTop}
                    to="/features"
                    className={({  isActive, isPending }) =>
                      `block py-2 sm:py-1 px-3 sm:px-1 text-gray-900 md:hover:text-blue-700 text-4xl leading-[5rem] md:leading-none md:text-[18px] ${isPending ? "pending" : isActive ? "active" : ""}`
                    }
                  >
                    Features
                  </NavLink>
                  <button className="md:hidden" onClick={() => toggleSubmenu('Product')}>
                    {submenuOpen === 'Product' ? (
                      <span className="flex items-center space-x-1 text-gray-900">
                        <svg className="w-10 h-10" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        <span className='text-4xl md:text-[18px]'>Back</span>
                      </span>
                    ) : (
                      <svg className="w-10 h-10" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                      </svg>
                    )}
                  </button>
                </div> */}
                {/* {submenuOpen === 'Features' && (
                  <ul className="ml-4 mt-2">
                    <li className="py-1">
                      <NavLink
                      onClick={scrollToTop}
                        to="/features/feature1"
                        className={({ isActive }) =>
                          `block text-gray-700 text-4xl leading-[5rem] md:leading-none md:text-[18px] ${isActive ? 'text-blue-700' : ''}`
                        }
                      >
                        Feature 1
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <NavLink
                      onClick={scrollToTop}
                        to="/features/feature2"
                        className={({ isActive }) =>
                          `block text-gray-700 text-4xl leading-[5rem] md:leading-none md:text-[18px] ${isActive ? 'text-blue-700' : ''}`
                        }
                      >
                        Feature 2
                      </NavLink>
                    </li>
                  </ul>
                )} */}
              </li>

              {/* <li>
                <NavLink
                  to="/pricing"
                  className={({ isActive, isPending }) =>
                    `block py-2 sm:py-1 px-3 sm:px-1 text-gray-900 md:hover:text-blue-700 text-4xl leading-[5rem] md:leading-none md:text-[18px] ${isPending ? "pending" : isActive ? "active" : ""}`
                  }
                >
                  Solution
                </NavLink>
              </li> */}

              <li>
                <NavLink
                onClick={scrollToTop}
                  to="/clients"
                  className={({  isActive, isPending }) =>
                    `block py-2 sm:py-1 px-3 sm:px-1 text-gray-900 md:hover:text-blue-700 text-4xl leading-[5rem] md:leading-none md:text-[18px] ${isPending ? "pending" : isActive ? "active" : ""}`
                  }
                >
                  Clients
                </NavLink>
              </li>

              <li>
                <NavLink
                onClick={scrollToTop}
                  to="/testimonials"
                  className={({ isActive, isPending }) =>
                    `block py-2 sm:py-1 px-3 sm:px-1 text-gray-900 md:hover:text-blue-700 text-4xl leading-[5rem] md:leading-none md:text-[18px] ${isPending ? "pending" : isActive ? "active" : ""}`
                  }
                >
                  Testimonials
                </NavLink>
              </li>

              <li>
                <NavLink
                onClick={scrollToTop}
                  to="/aboutus"
                  className={({ isActive, isPending }) =>
                    `block py-2 sm:py-1 px-3 sm:px-1 text-gray-900 md:hover:text-blue-700 text-4xl leading-[5rem] md:leading-none md:text-[18px] ${isPending ? "pending" : isActive ? "active" : ""}`
                  }
                >
                  About Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbarheader;
