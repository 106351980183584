import React, { useEffect } from 'react';
import Slider from "react-slick";
import blog from '../Assts/Blog.png';
import blog2 from '../Assts/Blog2.png';
import blog3 from '../Assts/Blog3.png';
import ButtonReuse from './../Components/ButtonReuse';
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';

// Blog data array
const blogPosts = [
    {
        id: 1,
        image: blog,
        date: '20 Feb 2022',
        author: 'By MyLeading Campus®',
        title: 'Innovative Teaching Strategies that Engage Every Learner',
        para: 'Ditch the lecture, embrace the spark! Discover engaging activities, tech tools, and classroom practices to ignite student curiosity and foster a love of learning.....',
    },
    {
        id: 2,
        image: blog2,
        date: '20 Feb 2022',
        author: 'By MyLeading Campus®',
        title: 'Innovative Teaching Strategies that Engage Every Learner',
        para: 'Ditch the lecture, embrace the spark! Discover engaging activities, tech tools, and classroom practices to ignite student curiosity and foster a love of learning.....',
    },
    {
        id: 3,
        image: blog3,
        date: '20 Feb 2022',
        author: 'By MyLeading Campus®',
        title: 'Innovative Teaching Strategies that Engage Every Learner',
        para: 'Ditch the lecture, embrace the spark! Discover engaging activities, tech tools, and classroom practices to ignite student curiosity and foster a love of learning.....',
    },
];

const Section11Home = () => {
    const navigate = useNavigate();
    // AOS initialization
    useEffect(() => {
        AOS.init({
            once: false,
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
        AOS.refresh();
    }, []);

    // Slick slider settings for mobile view
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <section className='my-11'>
            <div className="container">
                <h2 className='font-[Helvetica] font-bold text-[24px] sm:text-[40px] mb-2 capitalize text-center' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
                    Explore Our Inspiring Blog Posts
                </h2>
                <h3 className='text-[18px] sm:text-3xl font-medium  text-center w-[80%] block m-auto mb-[40px] ' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
                    Unleash your school's potential with insights, tips, and stories from educational experts and fellow school leaders.
                </h3>

                {/* Grid layout for desktop and slider for mobile */}
                <div className="hidden md:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mt-[100px] sm:mt-[50px] " data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
                    {blogPosts.map((post) => (
                        <div key={post.id} className='col-span-1'>
                            <div className="blog-box rounded-md bg-white shadow-md">
                                <img src={post.image} alt="" className='w-full' />
                                <div className="blog-info px-2 py-0 pb-4 pt-4">
                                    <ul className='flex'>
                                        <li className='text-[14px] font-semibold text-[#393939] mr-2'>{post.date}</li>
                                        <li className='w-[5px] h-[5px] bg-[#393939] rounded-full mr-2 mt-[8px]'></li>
                                        <li className='text-[14px] font-semibold text-[#393939] mr-2'>{post.author}</li>
                                    </ul>
                                    <h4 className='mt-3 mb-3 text-[20px] leading-6 font-semibold text-[#393939]'>
                                        {post.title}
                                    </h4>
                                    <p className='text-[16px] font-normal text-[#393939]'>
                                        {post.para}
                                    </p>
                                    <div className="btn-group mt-4">
                                        <button className='border rounded-[25px] border-[#393939] bg-transparent py-1 px-3 text-[14px] font-medium text-[#393939] mr-2'>School</button>
                                        <button className='border rounded-[25px] border-[#393939] bg-transparent py-1 px-3 text-[14px] font-medium text-[#393939] mr-2'>Learner</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Slick slider for mobile view */}
                <div className="md:hidden" data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
                    <Slider {...settings}>
                        {blogPosts.map((post) => (
                            <div key={post.id} className=''>
                                <div className="blog-box rounded-md bg-white shadow-md">
                                    <img src={post.image} alt="" className='w-full' />
                                    <div className="blog-info px-2 py-0 pb-4 pt-4">
                                        <ul className='flex'>
                                            <li className='text-[14px] font-semibold text-[#393939] mr-2'>{post.date}</li>
                                            <li className='w-[5px] h-[5px] bg-[#393939] rounded-full mr-2 mt-[8px]'></li>
                                            <li className='text-[14px] font-semibold text-[#393939] mr-2'>{post.author}</li>
                                        </ul>
                                        <h4 className='mt-3 mb-3 text-[20px] leading-6 font-semibold text-[#393939]'>
                                            {post.title}
                                        </h4>
                                        <p className='text-[16px] font-normal text-[#393939]'>
                                            {post.para}
                                        </p>
                                        <div className="btn-group mt-4">
                                            <button className='border rounded-[25px] border-[#393939] bg-transparent py-1 px-3 text-[14px] font-medium text-[#393939] mr-2'>School</button>
                                            <button className='border rounded-[25px] border-[#393939] bg-transparent py-1 px-3 text-[14px] font-medium text-[#393939] mr-2'>Learner</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className="text-center mt-6" data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400">
                    <ButtonReuse
                        onClick={() => {
                            navigate('/blog');
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                            })
                        }}
                        title="Read More"
                        className="mx-auto animate-updown px-[15px] md:px-[35px] py-[10px] md:py-[10px] text-[12px] md:text-[16px]"
                        style={{ display: 'inline-block', margin: '0 auto' }}
                    />
                </div>
            </div>
        </section>
    );
}

export default Section11Home;
