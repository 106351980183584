
import React from "react";
import { InfiniteMovingCards } from "./infinite-moving-cards";
import '../../App.css';
import ANMS from '../../Assts/ANMS.jpeg'
import BKGC from '../../Assts/BKGC.jpeg'
import DNBS from '../../Assts/DNBS.png'
import MDS from '../../Assts/MDS.jpeg'
import NMS from '../../Assts/NMS.png'
import NOPANY from '../../Assts/NOPANY.jpeg'
import NPS from '../../Assts/NPS.png'
import SDLC from '../../Assts/SDLC.jpeg'
import STJNS from '../../Assts/STJNS.png'
import TECHNO from '../../Assts/TECHNO.jpeg'
import TRINITY from '../../Assts/TRINITY.png'
import VEDANTA from '../../Assts/VEDANTA.png'
import VKS from '../../Assts/VKS.jpeg'
import BIMS from '../../Assts/BIMS.jpeg'

export function InfiniteMovingCardsDemo() {
  return (
    <div className="h-[20rem] rounded-md flex flex-col antialiased bg-white dark:bg-black dark:bg-grid-white/[0.05] items-center justify-center relative overflow-hidden">
    <InfiniteMovingCards
      items={testimonials}
      direction="right"
      speed="slow"
    />
  </div>
  );
}

const testimonials = [
  {
    img: TRINITY,
    name: "Trinity Public School"
  },
  {
    img: BIMS,
    name: "Bharatiya Vidya Bhavan"
  },
  {
    img: NMS,
    name: "National Model School Sodepur"
  },
  {
    img: MDS,
    name: "Maria's Day School Ramrajatala"
  },
  {
    img: VKS,
    name: "Vikramshila Academy"
  },
  {
    img: MDS,
    name: "Maria's Day School Mourigram"
  },
  {
    img: DNBS,
    name: "Don Bosco School, Malda"
  },
  {
    img: MDS,
    name: "Maria's International School Boinchi"
  },
  {
    img: ANMS,
    name: "Authpur National Model High School"
  },
  {
    img: MDS,
    name: "Maria's Day School South Howrah"
  },
  {
    img: VEDANTA,
    name: "The Vedanta Academy"
  },
  {
    img: MDS,
    name: "Maria's Day School South Howrah"
  },
  {
    img: STJNS,
    name: "St. John's Public School"
  },
  {
    img: MDS,
    name: "Maria's Day School Shibpur"
  },
  {
    img: TECHNO,
    name: "Techno India Group Public School, Krishnanagar"
  },
  {
    img: MDS,
    name: "Maria's Day School Howrah"
  },
  {
    img: TECHNO,
    name: "Techno India Group Public School, Sodepur"
  },
  {
    img: NPS,
    name: "National Progressive School"
  },
  {
    img: NOPANY,
    name: "Nopany Institute of Healthcare Studies"
  },
  {
    img: SDLC,
    name: "SD Law College"
  },
  {
    img: BKGC,
    name: "Bijoy Krishna Girls College"
  },
  {
    img: NOPANY,
    name: "Nopany Institute of Management Studies"
  },
];
