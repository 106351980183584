import React,{useState,useEffect, useContext} from 'react';
import ButtonReuse from '../Components/ButtonReuse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faArrowUp } from '@fortawesome/fontawesome-free-solid';
import { Link, NavLink } from 'react-router-dom';
import { AppContext } from '../App';

const Footer = () => {
  const [isvisible, setIsvisible] = useState(false);
  const {setOpen} = useContext(AppContext)
  const scrollTop = () =>{
    window.scrollTo({
      top:0,
      behavior: "smooth"
    })
  }
  const listenScroll = () =>{
    const heightToHide = 250;
    const windowsScroll = document.body.scrollTop || document.documentElement.scrollTop;
    setIsvisible(windowsScroll > heightToHide)
  }
  useEffect(() =>{
    window.addEventListener("scroll", listenScroll)
    return () => window.removeEventListener("scroll", listenScroll)
  },[])
  return (
    <>
       <footer className="bg-gradient-to-r from-[#7660FB] from-10% to-[#28A4D9] to-90% py-12">
      <div className="container">
        <div className="top-footer">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-10 gap-[2rem] md:gap-[7rem]">
            <div className="col-span-4">
              <h3
                className="text-[22px] font-semibold text-white font-['Poppins'] mb-[20px]"
                style={{ fontFamily: 'Poppins' }}
              >
                MYLEADING CAMPUS®
              </h3>
              <p className="text-[14px] text-[#fff]">
                Join our newsletter to stay up to date on features and releases.
              </p>
              {/* <input
                type="text"
                placeholder="Enter your email"
                className="border-none w-[100%] md:w-[59%] text-[14px] md:text-[16px] inline-block m-auto rounded-[25px] py-[10px] pl-[25px] shadow-md relative text-center md:text-start mt-[30px] md:mt[10px]"
              /> */}
              <ButtonReuse
                title="Get Demo"
                className="subscribe bg-gradient-to-r from-[#fff] to-[#fff] ml-2 text-blue-500 px-[25px] md:px-[35px] py-[10px] md:py-[10px] text-[10px] md:text-[16px] mt-[10px] hidden md:flex"
                style={{
                  marginTop: '35px',
                  textTransform: 'capitalize',
                  display: 'inline-block',background: 'linear-gradient(to right, #fff, #fff)'
                }}
                onClick={() => {
                  setOpen(true);
                }}
              />
              {/* mobile view */}
              <ButtonReuse
                title="Request a Demo"
                className="subscribe bg-gradient-to-r from-[#fff] to-[#fff] mt-3 text-blue-500 px-[25px] md:px-[35px] py-[10px] md:py-[10px] text-[10px] md:text-[16px] w-full block md:hidden"
                style={{
                  marginTop: '35px',
                  textTransform: 'capitalize',
                  display: 'inline-block',background: 'linear-gradient(to right, #fff, #fff)'
                }}
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
            <div className="col-span-2 pl-[0px] md:pl-[20px]">
              <h4 className="text-[18px] font-semibold text-white mb-3">
                Resources
              </h4>
              <ul className="flex flex-col">
                <li className="text-white font-normal capitalize mb-2" onClick={scrollTop}>
                  <NavLink to="/">API & Developers</NavLink>
                </li>
                <li className="text-white font-normal capitalize mb-2" onClick={scrollTop}>
                  <NavLink tos="/">Support</NavLink>
                </li>
                <li className="text-white font-normal capitalize mb-2" onClick={scrollTop}>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
                <li className="text-white font-normal capitalize" onClick={scrollTop}>
                  <NavLink to="/privacy">Privacy Policy</NavLink>
                </li>
              </ul>  
            </div>
            <div className="col-span-2 pr-[100px] md:pr-[0]">
              <h4 className="text-[18px] font-semibold text-white mb-3">
                Company
              </h4>
              <ul className="flex flex-col">
                <li className="text-white font-normal capitalize mb-2" onClick={scrollTop}>
                  <NavLink to="/aboutus">About us</NavLink>
                </li>
                <li className="text-white font-normal capitalize mb-2" onClick={scrollTop}>
                  <NavLink to="/">Careers</NavLink>
                </li>
                <li className="text-white font-normal capitalize" onClick={scrollTop}>
                  <NavLink to="/contact">Contact us</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-span-4 md:col-span-2">
              <h4 className="text-[18px] font-semibold text-white mb-3 md:block hidden">
                Follow Us
              </h4>
              <ul className='flex md:flex-col'>
                <a className="text-white font-normal capitalize mb-2" target='_blank' href='https://www.facebook.com/myleadingcampus.ERP/' rel='noreferrer'>
                  <FontAwesomeIcon icon={faFacebook} className="mr-2" />
                  <span className='hidden md:inline-block'>Facebook</span>
                </a>
                <a className="text-white font-normal capitalize mb-2" target='_blank' href='https://www.instagram.com/myleadingcampus/' rel='noreferrer'>
                  <FontAwesomeIcon icon={faInstagram} className="mr-2" />
                  <span className='hidden md:inline-block'>Instagram</span>
                </a>
                <a className="text-white font-normal capitalize" target='_blank' href='https://www.linkedin.com/company/myleading-campus/?originalSubdomain=in' rel='noreferrer'>
                  <FontAwesomeIcon icon={faLinkedin} className="mr-2" />
                  <span className='hidden md:inline-block'>LinkedIn</span>
                </a>
              </ul>
              <ul className='flex md:hidden mt-[15px] md:mt-[0]'>
                    <li className='text-white font-normal capitalize'>English</li>
                    <li className='text-white font-normal capitalize ml-4'>Privacy</li>
                    <li className='text-white font-normal capitalize ml-4'>Legal</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="middle-footer mt-5">
          <Link onClick={scrollTop} to={"/privacy"} className='text-[16px] font-normal text-[#fff] w-[100%] md:w-[43%] md:block hidden'>
            By subscribing you agree to with our <a href="/" style={{textDecoration: "underline"}}> Privacy Policy</a> and provide
            consent to receive updates from our company.
          </Link>
          <p className='text-[16px] font-normal text-[#fff] w-[100%] md:w-[43%] md:hidden block'>Copyright © 2023, MyLeading Campus ® "Managed by Snowball Innovations</p>
        </div>
      </div>
    </footer>
    <div>
       <div className="container text-center mt-7 mb-9 hidden md:block">
           <p className='text-[16px] font-normal text-[##000000]'>Copyright © 2023, MyLeading Campus ® "Managed by Snowball Innovations</p>
       </div>
    </div>
    {isvisible && (
         <FontAwesomeIcon className='scroll_top text-white text-xl shadow-lg' icon={faArrowUp} onClick={scrollTop}/>
    )}
    </>
  );
};

export default Footer;
